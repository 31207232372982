<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Halaman Tidak Ditemukan</div>
    <div>Halaman telah dilarang, dihapus, atau sama sekali tidak ada</div>
    <div>Tersesat ? <i class="fa fa-frown-o" aria-hidden="true"></i></div>
    <div class="font-weight-bold font-size-70 mb-1">Harusnya 404 —</div>
    <router-link to="/" class="btn btn-outline-primary width-100">Go Back</router-link>
  </div>
</template>
<script>
export default {
  name: 'CuiError404',
}
</script>
